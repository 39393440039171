import React from 'react'

const Fail = () => {
  return (
    <div>
      <h1 className='text-5xl font-bold'>Fail</h1>
    </div>
  )
}

export default Fail;